


import Component from 'vue-class-component';
import {Prop, Vue, Watch} from 'vue-property-decorator';
import {mapGetters, mapState} from 'vuex';
import {TUser} from '@/_types/user.type';
import {TEvent} from '@/_types/event.type';
import {TContact} from '@/_types/contact.type';
import {ScheduleType, TEventDay, TMeeting, TTimeSlot} from '@/_types/meeting/meeting.type';
import DateTimeHelper from '@/_helpers/date-time.helper';
import {MeetingStatus} from '@/_modules/meeting-rooms/types/meeting-status.enum';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import MeetingsHead from '@/_modules/meetings/components/meetings-head/meetings-head.vue';

@Component({
  name: 'contact-schedule',
  components: {MeetingsHead},
  computed: {
    ...mapGetters({
      user: '_userStore/user',
      event: '_eventStore/event',
      contact: 'promoPageStore/contact',
      meetingsCount: 'notificationsStore/meetingsCount',
      noticedMeetingsCount: 'notificationsStore/noticedMeetingsCount',
      getMeetingsByUserId: 'meetingsStore/getMeetingsByUserId',
      getSelectedDate: 'meetingsStore/getContactSelectedDate',
      getSidebarSelectedDate: 'meetingsStore/getSidebarContactSelectedDate',
      isMeetingCancel: 'meetingsStore/isMeetingCancel',
    }),
    ...mapState('meetingsStore', {
      meetingsByUserId: 'meetingsByUserId'
    })
  }
})

export default class ContactSchedule extends Vue {

  // -- Data
  public readonly user: TUser;
  public readonly event: TEvent;
  public readonly getMeetingsByUserId: Function;
  public getSelectedDate: TEventDay;
  public getSidebarSelectedDate: TEventDay;
  public readonly contact: TContact;
  public meetings: TMeeting[]
  public eventDays: TEventDay[] = [];
  public readonly meetingsByUserId: TMeeting[];
  public meetingsByDayStore: { [date: string]: TMeeting } = {};
  public timeSlots: TTimeSlot[] = [];
  public getUserScheduleDataInterval: number = null
  public dateStart: Date = null;
  public dateEnd: Date = null;

  public month: number = null;
  public week: number = null;

  public nowTimestamp: number = (new Date()).getTime();
  public nowTimestampUpdaterIntervalId: number = null;
  public isMeetingCancel: false;
  public tempIndex: number;
  public tempTimeSlot: TTimeSlot;
  // -- /Data

  // -- Computed

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get currentUserId(): number {
    return (this.user && this.user.id) || null;
  }

  public get currentContactId(): number {
    return (this.contact && this.contact.id) || null;
  }

  public get userId(): number {
    return (this.contactData && this.contactData.user && this.contactData.user.id) || null;
  }

  public get contactId(): number {
    return (this.contactData && this.contactData.id) || null;
  }

  public get meetingsByDay(): TMeeting {
    const momentDate = DateTimeHelper.getDateWithoutHoursMinutes(this.selectedDate.date);
    return this.meetingsByDayStore[momentDate];
  }

  public get getMeetings(): TMeeting[] {
    if (!this.userId) {
      return [];
    }

    return this.getMeetingsByUserId(this.userId).filter((item: TMeeting) => {
      return item.status !== MeetingStatus.Canceled;
    });
  }

  public get selectedDate(): TEventDay {
    if(this.type === ScheduleType.SIDEBAR_CONTACT_INFO_SCHEDULE) {
      return this.getSidebarSelectedDate;
    } else {
      return this.getSelectedDate;
    }
  }

  public get selectedDayMeetingList(): { [key: string]: TMeeting } {
    if (!this.selectedDate.date) {
      return {};
    }
    const meetings = (this.getMeetings || []);
    const filterDate = DateTimeHelper.getDateWithoutHoursMinutes(this.selectedDate.date);
    if (!meetings.length) {
      return {};
    }

    return meetings.filter((item: TMeeting) => DateTimeHelper.getDateWithoutHoursMinutes(item.date_start) === filterDate)
      .reduce((acc: { [key: string]: TMeeting }, item: TMeeting) => {
        if(!acc[DateTimeHelper.getFullDate(item.date_start)]) {
          acc[DateTimeHelper.getFullDate(item.date_start)] = item;
        }

        if(this.currentContactId === item.creator_contact.id) {
          acc[DateTimeHelper.getFullDate(item.date_start)] = item;
        }
        return acc;
      }, {});
  }

  public get timeSlotsPastTimeMark(): number {
    // Current time - 30 minutes, as specified in AW-1779
    // See isTimeSlotPast for comparison
    return this.nowTimestamp - (1000 * 60 * 30);
  }

  public set timeSlotsPastTimeMark(value: number) {
    this.nowTimestamp = value;
  }

  // -- /Computed

  // -- Props
  @Prop({ type: Object, default: null })
  public readonly contactData: TContact;

  @Prop({ type: Object, default: null })
  public readonly promoPage: TPromoPage;

  @Prop({ type: String, default: ScheduleType.CONTACT_SCHEDULE })
  public readonly type: ScheduleType;

  // -- /Props

  // -- watch

  @Watch('event', {immediate: true})
  private onEventChanged(): void {
    if (!this.event) {
      return;
    }
    this.getEventDays();
    this.createTimeSlots();
  }

  @Watch('meetingsByUserId')
  private onMeetingsChanged(): void {
    this.createTimeSlots();
  }

  @Watch('selectedDate', {immediate: true})
  private onSelectedDateChanged(): void {
    this.createTimeSlots();
  }

  @Watch('contactData', {immediate: true})
  private onMeetingsCountChanged(): void {
    this.getUserScheduleData();
  }

  @Watch('isMeetingCancel')
  private onCancelMeetingChanged(): void {
    if(this.isMeetingCancel) {
      this.cancelMeeting(this.tempTimeSlot, this.tempIndex);
    }
  }
  // -- /watch

  // -- Methods
  public mounted(): void {
    this.getUserScheduleDataInterval = window.setInterval(() => {
      this.getUserScheduleData();
    }, 10000);

    this.startNowTimestampUpdate();
  }
  public beforeDestroy(): void{
    clearInterval(this.getUserScheduleDataInterval);
    this.stopNowTimestampUpdate();
  }
  public getEventDays(): void {
    if (!this.event) {
      return;
    }
    this.eventDays = [];

    const dateStart = this.event.date_start;
    const dateEnd = this.event.date_end;
    let iteratedDate = dateStart; // Объект JS-даты для цикла
    const currentMoment = this.$moment();
    const day = 60 * 60 * 24 * 1000;

    for (let i = 0; i <= DateTimeHelper.getDateDiff(dateEnd, dateStart); i++) {
      if (i === 0) {
        iteratedDate = dateStart;
      }

      const eventDay: TEventDay = {
        month: iteratedDate.getMonth() + 1,
        monthName: this.$moment(iteratedDate).format('MMMM'),
        week: this.$moment(iteratedDate).week(),
        date: iteratedDate,
        dayNumber: this.$moment(iteratedDate).format('D').padStart(2, '0'),
        badgeNotification: false
      };

      this.eventDays = [...this.eventDays, eventDay];

      if (currentMoment.isSame(iteratedDate, 'day')) {
        if (this.type === ScheduleType.SIDEBAR_CONTACT_INFO_SCHEDULE) {
          this.$store.dispatch('meetingsStore/sideBarContactScheduleDate', eventDay);
        } else {
          this.$store.dispatch('meetingsStore/contactScheduleDate', eventDay);
        }
      }

      iteratedDate = new Date(iteratedDate.getTime() + day);
    }

    if (!this.selectedDate.date) {
      if (this.type === ScheduleType.SIDEBAR_CONTACT_INFO_SCHEDULE) {
        this.$store.dispatch('meetingsStore/sideBarContactScheduleDate', this.eventDays[0]);
      } else {
        this.$store.dispatch('meetingsStore/contactScheduleDate', this.eventDays[0]);
      }
    }
  }

  // slots
  public setSlotTime(dateValue: Date, hours: number, minutes: number, seconds: number): Date {
    const date = new Date(dateValue);
    date.setHours(hours, minutes, seconds);
    return date;
  }

  public addMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() + minutes * 60000);
  }

  public createTimeSlots(): void {
    this.timeSlots = [];
    const event = this.event;
    if (
      !this.selectedDate
      || !this.selectedDate.date
      || !event.date_start
      || !event.date_end
    ) {
      return;
    }

    const eventDateStartMoment = this.$moment(event.date_start);
    const eventDateEndMoment = this.$moment(event.date_end);
    if (eventDateStartMoment.isSameOrAfter(eventDateEndMoment)) {
      return;
    }

    const selectedDayMeetingList = this.selectedDayMeetingList;
    const selectedDateMoment = this.$moment(this.selectedDate.date);
    let iterateTimeMoment = selectedDateMoment.clone()
      .hours(0).minutes(0).seconds(0).milliseconds(0);
    const endTimeMoment = iterateTimeMoment.clone().add(1, 'day');
    const uniqueDates: { [dateKey: string]: boolean } = {};

    while (iterateTimeMoment.isBefore(endTimeMoment)) {
      const currentTimeDateKey = DateTimeHelper.getFullDate(iterateTimeMoment.toDate());
      const nextTimeMoment = iterateTimeMoment.clone().add(30, 'minutes');
      if (
        iterateTimeMoment.isBefore(eventDateStartMoment)
        || !!uniqueDates[currentTimeDateKey]
      ) {
        iterateTimeMoment = nextTimeMoment;
        continue;
      } else if (nextTimeMoment.isAfter(eventDateEndMoment)) {
        break;
      }

      this.timeSlots.push({
        dateStart: iterateTimeMoment.toDate(),
        dateEnd: nextTimeMoment.toDate(),
        meeting: selectedDayMeetingList[currentTimeDateKey] || null,
      });

      uniqueDates[currentTimeDateKey] = true;
      iterateTimeMoment = nextTimeMoment;
    }
  }

  public isTimeSlotAvailable(timeSlot: TTimeSlot): boolean {
    if (!timeSlot.meeting) {
      return true;
    }

    // Если встреча подтверждена, наш бэк не дает создать новую заявку на то же время
    if (timeSlot.meeting.status === MeetingStatus.Confirmed) {
      return false;
    }

    // Иначе встреча есть.
    //
    // Если абонент, на список встреч к-рого смотрим, сам же создал встречу,
    // значит, он пометил слот недоступным
    if ((timeSlot.meeting.contact.id === timeSlot.meeting.user_contact.id)) {
      return false;
    }

    // // Если встреча чужая, то оставляем слот свободным
    if ((timeSlot.meeting.contact.id !== this.currentContactId) && (timeSlot.meeting.user_contact.id !== this.currentContactId)) {
      return true;
    }

    // // Если залогиненный не создатель, но участник, делаем слот занятым
    if (!timeSlot.meeting.is_creator && timeSlot.meeting.user_contact.id === this.currentContactId) {
      return false;
    }

    // // Остается ситуация, что встреча принадлежит мне
    // // В таком случае слот не свободен, чтобы я не мог нажимать несколько раз
    return false;
  }

  public isTimeSlotPast(timeSlot: TTimeSlot): boolean {
    return timeSlot.dateEnd.getTime() < this.timeSlotsPastTimeMark;
  }

  public getUserScheduleData(): void {
    if (!this.userId) {
      return;
    }

    this.$store.dispatch('meetingsStore/requestUserMeetings', {
      userId: this.userId,
      force: true
    });

  }

  public isCurrentUserCalendarOwner(): boolean {
    return this.contactData && (this.currentUserId === this.contactData.user.id);
  }

  public isMeetingMine(timeSlot: TTimeSlot): boolean {
    return timeSlot.meeting.contact.user.id !== this.currentUserId;
  }

  public async requestMeeting(timeSlot: TTimeSlot, index: number): Promise<void> {
    const dateStart = timeSlot.dateStart;
    const dateEnd = timeSlot.dateEnd;

    if (timeSlot.isProcessing) {
      return;
    }

    timeSlot.isProcessing = true;

    const requestMeetingResponse = await this.$store.dispatch('meetingsStore/requestMeeting', {
      event_id: this.$route.params.eventId,
      user_id: this.contactData.user.id,
      date_start: DateTimeHelper.dateToApiDate(new Date(dateStart)),
      date_end: DateTimeHelper.dateToApiDate(new Date(dateEnd)),
    });

    timeSlot.isProcessing = false;

    if (requestMeetingResponse.status !== 200 || requestMeetingResponse.error) {
      timeSlot.errors = timeSlot.errors || [];
      timeSlot.errors = [...timeSlot.errors, {text: requestMeetingResponse.error ? requestMeetingResponse.error : this.$t('errors.meetingGeneralError')}];

      this.$set(this.timeSlots, index, {...timeSlot});
      return;
    }

    if (requestMeetingResponse && requestMeetingResponse.data) {
      timeSlot.meeting = requestMeetingResponse.data;
    }

  }

  public showCancelConfirmation(timesSlot: TTimeSlot, index: number, ): void {
    this.tempIndex = index;
    this.tempTimeSlot = timesSlot;
    this.$store.dispatch('meetingsStore/setMeetingCancelPopupVisible', true);
  }

  public async cancelMeeting(timeSlot: TTimeSlot, index: number): Promise<void> {

    if (timeSlot.isProcessing) {
      await this.$store.dispatch('meetingsStore/setMeetingCancelPopupVisible', false);
      await this.$store.dispatch('meetingsStore/setMeetingCancel', false);
      return;
    }

    timeSlot.isProcessing = true;

    const cancelMeetingResponse = await this.$store.dispatch('meetingsStore/cancelMeeting', {
      event_id: this.eventId,
      meeting_id: timeSlot.meeting.id,
    });

    timeSlot.isProcessing = false;

    if (!cancelMeetingResponse || cancelMeetingResponse.status !== 202 || cancelMeetingResponse.error) {
      timeSlot.meeting.errors = timeSlot.meeting.errors || [];
      timeSlot.meeting.errors.push({
        text: cancelMeetingResponse && cancelMeetingResponse.error ? cancelMeetingResponse.error : this.$t('errors.meetingGeneralError'),
      });
      this.$set(this.timeSlots, index, {...timeSlot});
      return;
    }

    timeSlot.meeting = null;
    this.$set(this.timeSlots, index, {...timeSlot});

    this.$store.dispatch('meetingsStore/setMeetingCancelPopupVisible', false);
    this.$store.dispatch('meetingsStore/setMeetingCancel', false);

  }

  public clearMeetingErrors(timeSlot: TTimeSlot, index: number): void{
    const newItem = {...this.timeSlots[index]};
    newItem.errors = [];
    this.$set(this.timeSlots, index, newItem);
  }

  public showTime(date: Date): string {
    return DateTimeHelper.getHoursMinutes(date);
  }
  private startNowTimestampUpdate(): void {
    this.nowTimestampUpdaterIntervalId = window.setInterval(() => {
      this.timeSlotsPastTimeMark = (new Date()).getTime();
    }, 1000 * 60);
  }

  private stopNowTimestampUpdate(): void {
    window.clearInterval(this.nowTimestampUpdaterIntervalId);
  }

  private isTimeSlotMeetingCurrent(timeSlot: TTimeSlot): boolean {
    if (!timeSlot.meeting) {
      return false;
    }

    return timeSlot.meeting.date_start.getTime() < this.nowTimestamp
      && timeSlot.meeting.date_end.getTime() > this.nowTimestamp;
  }

  // -- /Methods

}

